<template>
  <!-- NAME[epic=采购] 采购订单 -->
  <div class="orderTest-container">
    <el-form ref="form" inline :model="form">
      <el-form-item prop="operator_id">
        <el-select
          v-model="form.operator_id"
          clearable
          placeholder="请选择经办人"
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in jbrList"
            :key="index"
            :label="item.user_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="supp_id">
        <el-select
          v-model="form.supp_id"
          clearable
          filterable
          placeholder="请选择供应商"
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in gysList"
            :key="index"
            :label="item.supp_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="bill_code">
        <el-input
          v-model="form.bill_code"
          clearable
          placeholder="请输入单号"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <goods-search
          ref="goodsSearch"
          :is-table="false"
          @select-goods-all="selectGoodsId"
        ></goods-search>
      </el-form-item>
      <el-form-item prop="status">
        <el-select
          v-model="form.status"
          clearable
          placeholder="审核状态"
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in ztList"
            :key="index"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="pay_status">
        <el-select
          v-model="form.pay_status"
          clearable
          placeholder="结算状态"
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in jsList"
            :key="index"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="print_status">
        <el-select
          v-model="form.print_status"
          clearable
          placeholder="打印状态"
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in dyList"
            :key="index"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="start_time">
        <el-date-picker
          v-model="form.start_time"
          type="date"
          placeholder="开始时间"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          style="width: 160px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="end_time">
        <el-date-picker
          v-model="form.end_time"
          type="date"
          placeholder="结束时间"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          style="width: 160px"
        ></el-date-picker>
      </el-form-item>
      <el-form-item prop="is_check_at">
        <el-checkbox
          v-model="form.is_check_at"
          :true-label="1"
          :false-label="0"
        >
          按审核时间
        </el-checkbox>
      </el-form-item>
      <el-form-item prop="is_depot">
        <el-select
          v-model="form.is_depot"
          clearable
          placeholder="入库状态"
          style="width: 140px"
        >
          <el-option
            v-for="(item, index) in depotList"
            :key="index"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="remark">
        <el-input
          v-model="form.remark"
          clearable
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="warning" @click="handleClear">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleAdd">添加</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>

    <el-table
      v-loading="loading"
      stripe
      :data="list"
      show-summary
      :summary-method="summaryFunction"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <!-- <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template> -->
      </el-table-column>
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
      ></el-table-column>
      <el-table-column
        v-for="(item, index) in finallyColumns"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        width=""
        align="center"
      >
        <template #default="{ row }">
          <div
            v-if="item.label == '单号'"
            class="underline"
            @click="handleCheck(row)"
          >
            {{ row[item.prop] }}
          </div>
          <div v-else>{{ row[item.prop] }}</div>
        </template>
      </el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ $index, row }">
          <div v-if="row.status_text == '已作废'">
            <el-button
              v-if="row.status != 0"
              type="text"
              @click.native.prevent="handleCheck(row)"
            >
              查看
            </el-button>
          </div>
          <div v-else>
            <el-button
              v-if="row.status != 0"
              type="text"
              @click.native.prevent="handleCheck(row)"
            >
              查看
            </el-button>
            <!--            authbutton[3].is_check == 1 &&-->
            <el-button
              v-if="row.status == 0"
              type="text"
              @click.native.prevent="handleReview($index, row)"
            >
              审核
            </el-button>
            <!--新增入库操作authbutton[3].is_check == 1 &&-->
            <el-button
              v-if="row.status == 1 && row.is_depot != 1"
              type="text"
              @click.native.prevent="handlestore($index, row)"
            >
              入库
            </el-button>
            <el-button
              v-if="authbutton[3].is_check == 1"
              type="text"
              @click.native.prevent="handleDeleteRow($index, row)"
            >
              作废
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handlePrint($index, row)"
            >
              打印
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <add ref="add" @refresh="fetchData"></add>
    <review ref="review" @refresh="fetchData"></review>
    <my-check ref="check"></my-check>
    <newck ref="newcheck" />
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="CD"
      :data_id="data_id"
      :type="1"
    ></ruilang-display-dialog>
    <store ref="store" @refresh="fetchData" />
  </div>
</template>
<script>
  import _ from 'lodash'
  import {
    jbrSelect,
    gysSelect,
    getOrderList,
    purchaseDelete,
  } from '@/api/purchase'
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import Add from './components/add'
  import Review from './components/review'
  import MyCheck from './components/check'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { downloadFile, postAction } from '@/api/Employee'
  import { getSelect } from '@/api/saleOrder'
  import store from './components/store'
  import newck from './components/newck.vue'
  export default {
    name: 'PurchaseOrder',
    components: {
      store,
      GoodsSearch,
      Add,
      Review,
      MyCheck,
      RuilangDisplayDialog,
      newck,
    },
    data() {
      return {
        loading: false,
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        jbrList: [],
        data_id: 0,
        gysList: [],
        depotList: [
          {
            id: '0',
            value: '待入库',
          },
          {
            id: '1',
            value: '全部入库',
          },
          {
            id: '2',
            value: '部分入库',
          },
        ],
        ztList: [
          {
            id: '0',
            value: '待审核',
          },
          {
            id: '1',
            value: '已审核',
          },
          {
            id: '2',
            value: '已作废',
          },
        ],
        jsList: [
          {
            id: '0',
            value: '未结清',
          },
          {
            id: '1',
            value: '已结清',
          },
        ],
        dyList: [
          {
            id: '0',
            value: '未打印',
          },
          {
            id: '1',
            value: '已打印',
          },
        ],
        form: {
          operator_id: '', //经办人id
          supp_id: '', //经销商id
          goods_id: '', //商品名称或助记码
          status: '', //状态 0待审核  1已审核  2已作废
          pay_status: '', //结算状态 0未结清 1已结清
          print_status: '', //打印状态  0未打印  1已打印
          start_time: '', //开始时间
          end_time: '', //结束时间
          bill_code: '', //单号
          remark: '', //备注
          is_check_at: '', //是否 按审核时间   1是，0不是
          pageNo: 1,
          pageSize: 10,
          sort: '', //排序字段，默认id
          order: '', //排序类型，默认asc
        },
        list: [],
        checkList: [
          '单号',
          '供应商',
          '经办人',
          '采订金额',
          '创建时间',
          '已入库金额',
          // '已入库应付',
          '已支付',
          '待支付',
          '待入库金额',
          '审核状态',
          '入库状态',
          '结算',
          '备注',
          '打印',
        ],
        total_res: {},
        columns: [
          {
            order: 1,
            label: '单号',
            prop: 'bill_code',
            width: '140',
          },
          {
            order: 2,
            label: '供应商',
            prop: 'supp_name',
            width: '140',
          },
          {
            order: 3,
            label: '经办人',
            prop: 'operator',
            width: '140',
          },
          {
            order: 4,
            label: '创建时间',
            prop: 'create_at',
            width: '140',
          },
          {
            order: 5,
            label: '采订金额',
            prop: 'final_money',
            width: '140',
          },
          {
            order: 6,
            label: '已入库金额',
            prop: 'in_money',
            width: '140',
          },
          // {
          //   order: 7,
          //   label: '已入库应付',
          //   prop: 'pay_money_must',
          //   width: '140',
          // },
          {
            order: 8,
            label: '已支付',
            prop: 'pay_money',
            width: '140',
          },
          {
            order: 9,
            label: '待支付',
            prop: 'arrears',
            width: '140',
          },
          {
            order: 10,
            label: '待入库金额',
            prop: 'in_money_un',
            width: '140',
          },

          {
            order: 11,
            label: '审核状态',
            prop: 'status_text',
            width: '140',
          },
          {
            order: 12,
            label: '入库状态',
            prop: 'is_depot_text',
            width: '140',
          },
          {
            order: 13,
            label: '结算',
            prop: 'pay_text',
            width: '140',
          },
          {
            order: 14,
            label: '备注',
            prop: 'remark',
            width: '140',
          },
          {
            order: 15,
            label: '打印',
            prop: 'print_count',
            width: '140',
          },
        ],
        authbutton: [],
      }
    },
    computed: {
      finallyColumns() {
        let arr = []
        this.checkList.forEach((item) => {
          arr.push(this.columns.filter((item2) => item2.label == item)[0])
        })
        return _.sortBy(arr, (item) => item.order)
      },
    },
    watch: {},
    async created() {
      // 经办人下拉
      await jbrSelect().then((res) => {
        this.jbrList = res.data.rows
      })
      // 供应商下拉
      await gysSelect().then((res) => {
        this.gysList = res.data.rows
      })
      await getSelect().then((res) => {
        // this.areaList = res.data.cust_area //区域
        console.log(res.data.fun_list, '控控制')
        this.authbutton = res.data.fun_list
      })
      await this.fetchData()
    },
    mounted() {
      postAction('/purchaseAdmin/order/price-door').then((res) => {
        this.$refs.add.showBtn = res.data.door
      })
    },
    methods: {
      handleExport() {
        const data = JSON.parse(JSON.stringify(this.form))
        delete data.pageNo
        delete data.pageSize
        downloadFile('/purchaseAdmin/order/index-export', '采购订单.xlsx', data)
      },
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await getOrderList(this.form)
        console.log(data)
        this.list = data.rows
        this.total = Number(data.total)
        this.total_res = data.total_res
        this.loading = false
      },
      selectGoodsId(val) {
        this.form.goods_id = val.goods_id
      },
      handleQuery() {
        this.form.pageSize = 10
        this.form.pageNo = 1
        this.fetchData()
      },
      handleClear() {
        this.$refs.form.resetFields()
        this.$refs.goodsSearch.resetForm()
        this.form.goods_id = ''
      },
      handleAdd() {
        this.$refs.add.showDialog = true
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },

      // 表格操作
      handleCheck(rows) {
        console.log(rows.id)
        if (rows.is_batch == 1) {
          this.$refs.newcheck.id = rows.id
          this.$refs.newcheck.showDialog = true
        } else {
          this.$refs.check.id = rows.id
          this.$refs.check.showDialog = true
        }
      },
      handlestore(index, rows) {
        this.$refs.store.id = rows.id
        this.$refs.store.showDialog = true
      },
      handleReview(index, rows) {
        this.$refs.review.id = rows.id
        this.$refs.review.showDialog = true
      },
      handleDeleteRow(index, rows) {
        this.$confirm('确定作废此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            purchaseDelete({ order_id: rows.id }).then((res) => {
              this.fetchData()
              this.$message.success(res.msg)
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handlePrint(index, rows) {
        console.log(rows)
        this.data_id = rows.id
        this.$refs.RuilangDisplayDialog.dialogFormVisible = true
      },
      // 表格合计fun
      summaryFunction(param) {
        const { columns, data } = param
        console.log(columns, data)
        let resArray = Object.keys(this.total_res)
        let sums = []
        columns.forEach((col, idx) => {
          console.log(col)
          resArray.forEach((item) => {
            if (col.property == item) {
              sums[idx] = this.total_res[item]
            }
          })
          if (idx == 0) {
            sums[idx] = '合计'
          }
        })
        console.log('sss', sums)
        return sums
      },
    },
  }
</script>
<style lang="scss" scoped></style>
